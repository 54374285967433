document.addEventListener('turbo:load', () => {
  var flatpickr_options = {
    altInput: true,
    altFormat: "D M j Y",
    dateFormat: "Ymd"
  }
  flatpickr('.flatpickr-init', flatpickr_options)
})

document.addEventListener('turbo:load', () => {
  var flatpickr_options = {
    enableTime: true,
    altInput: true,
    altFormat: "D M j Y @ h:i K",
    dateFormat: "Z"
  }
  flatpickr('.flatpickr-init-show-time', flatpickr_options)
})

document.addEventListener('turbo:load', () => {
  var flatpickr_options = {
    enableTime: true,
    altInput: true,
    altFormat: "D M j Y @ h:i K",
    dateFormat: "YmdHiS"
  }
  flatpickr('.flatpickr-init-new-show-time', flatpickr_options)
})

document.addEventListener('turbo:load', () => {
  var flatpickr_options = {
    inline: true,
    onChange: function(selectedDates, dateStr, instance) {
      var form = document.querySelector('#expected-on-form');
      Rails.fire(form, "submit")
    },
    onDayCreate: function(dObj, dStr, fp, dayElem) {
      var highlightDays = JSON.parse(fp.element.dataset.schedule || null)
      if (highlightDays.indexOf(dayElem.dateObj.toISOString().slice(0,10)) !== -1) {
        dayElem.className += " route-available";
      }
    }
  }
  flatpickr('.expected-on-picker-init', flatpickr_options)
})

document.addEventListener('turbo:load', () => {
  var flatpickr_options = {
    minDate: 'today',
    inline: true,
    onChange: function(selectedDates, dateStr, instance) {
      var input = instance._input;
      var form = $(input).closest('form')[0];
      Rails.fire(form, "submit")
    }
  }
  flatpickr('.new-po-picker-init', flatpickr_options)
})

document.addEventListener('turbo:load', () => {
  $('#main').on('show.bs.dropdown', '.available-on-dropdown', function () {
    var flatpickr_options = {
      inline: true,
      minDate: 'today',
      dateFormat: "Ymd"
    }
    flatpickr('.available-on-picker-init', flatpickr_options)
  })
})

document.addEventListener('turbo:load', function() {
  $('#order-receiving').on('click', 'button.add-item', function(event) {
    var element = $(this).closest('.receiving-order-item').find('.flatpickr-init2')

    if (element.hasClass('flatpickr-input'))
      return

    var flatpickr_options = {
      altInput: true,
      minDate: 'today',
      altFormat: "D M j Y",
      dateFormat: "Ymd"
    }

    flatpickr('#order-receiving .flatpickr-init2:not(.flatpickr-input)', flatpickr_options)
  })

  var flatpickr_options = {
    altInput: true,
    minDate: 'today',
    altFormat: "D M j Y",
    dateFormat: "Ymd"
  }

  flatpickr('#order-receiving .flatpickr-init2', flatpickr_options)

})

document.addEventListener("turbo:before-cache", function() {
  $('.flatpickr-calendar').remove()
  $("input.flatpickr-input[type='hidden']").each(function() {
    $(this).attr('type', 'text')
    $(this).removeClass('flatpickr-input')
    $(this).next('input').remove()
  })
})
