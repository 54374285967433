import React from "react"
import { SalesOrderItemEdit } from "./SalesOrderItemEdit"
import { SalesOrderItemNew } from "./SalesOrderItemNew"
import { OrderInfo } from "./OrderInfo"
import { BuyerOrderService } from "../../services/BuyerOrderService"
import { InventoryForecastService } from "../../services/InventoryForecastService"
import { OrderItemMoveService } from "../../services/OrderItemMoveService"
import { ToastContainer } from 'react-toastify'


import { asCurrency } from "../../services/Utils"
import _ from "lodash"

export class SalesOrderEdit extends React.Component {
  constructor(props) {
    super(props)

    this.onItemCreate = this.onItemCreate.bind(this)
    this.onItemDestroy = this.onItemDestroy.bind(this)
    this.onItemUpdate = this.onItemUpdate.bind(this)
    this.onNewItemSubtotalUpdate = this.onNewItemSubtotalUpdate.bind(this)
    this.onChangeBuyerOrder = this.onChangeBuyerOrder.bind(this)
    this.changeOrder = this.changeOrder.bind(this)
    this.changeSortMethod = this.changeSortMethod.bind(this)
    this.save = _.debounce(this.save, 1000)
    this.toggleName = this.toggleName.bind(this)
    this.toggleForecast = this.toggleForecast.bind(this)

    let buyer_order = props.buyer_order
    buyer_order.discount = asCurrency(buyer_order.discount)

    this.state = {
      forecasts: {},
      showForecast: false,
      open_orders: this.props.open_orders,
      sortMethod: buyer_order.status == 'draft' ? 'time-added' : 'name',
      buyer_order: buyer_order,
      newItemSubtotal: 0
    }
  }

  save() {
    const buyer_order = this.state.buyer_order

    const scrubbed_buyer_order = {
      discount: buyer_order.discount.replace('$', ''),
      id: buyer_order.id,
      name: buyer_order.name,
      note_from_seller: buyer_order.note_from_seller,
      note_from_buyer: buyer_order.note_from_buyer
    }

    BuyerOrderService.update(scrubbed_buyer_order).then((buyer_order) => {
      if (buyer_order) { this.setState({buyer_order: buyer_order}) }
      },
      (error) => { toast(error.message, { className: "bg-danger text-white" }) }
    )
  }

  changeSortMethod(value) {
    this.setState({ sortMethod: value })
  }

  changeOrder(order_item_id, move_type, new_order_id, notes, reason) {
    let move_attributes = { move_type: move_type, notes: notes, reason: reason }
    if (new_order_id) {
      move_attributes.to_order_id = new_order_id
    }
    OrderItemMoveService.create(order_item_id, move_attributes).then((response) => {
      const to_order = response.to_order
      let open_orders = this.state.open_orders
      let indexToReplace = _.findIndex(open_orders, { id: to_order.id })
      if (indexToReplace == -1) {
        open_orders.push(to_order)
      } else {
        open_orders[indexToReplace] = to_order
      }
      this.setState({ open_orders: open_orders })
    })
    if ( move_type == 'move' ) {
      this.onItemDestroy(order_item_id)
    } else if ( move_type == 'backorder ') {

      let buyer_order = this.state.buyer_order
      let item_to_replace = _.find(buyer_order.buyer_order_items, { id: order_item_id })
      item_to_replace.quantity = 0

      this.setState({ buyerOrder: buyer_order, showAdvanced: false})
    }
  }

  onChangeBuyerOrder(event) {
    let buyer_order = this.state.buyer_order
    buyer_order[event.target.name] = event.target.value
    this.setState({ buyer_order: buyer_order }, this.save)
  }

  onItemCreate(buyer_order_item) {
    let buyer_order = this.state.buyer_order
    buyer_order.buyer_order_items.push(buyer_order_item)
    this.setState({ buyer_order: buyer_order, newItemSubtotal: 0 })
  }

  onItemDestroy(order_item_id) {
    let buyer_order = this.state.buyer_order
    let item_to_remove = _.find(buyer_order.buyer_order_items, { id: order_item_id })
    const new_buyer_order_items = _.without(buyer_order.buyer_order_items, item_to_remove)
    buyer_order.buyer_order_items = new_buyer_order_items
    this.setState({ buyer_order: buyer_order })
  }

  onItemUpdate(new_buyer_order_item) {
    let buyer_order = this.state.buyer_order
    let indexToReplace = _.findIndex(buyer_order.buyer_order_items, { id: new_buyer_order_item.id })
    buyer_order.buyer_order_items[indexToReplace] = new_buyer_order_item
    this.setState({ buyer_order: buyer_order })
  }

  onNewItemSubtotalUpdate(newItemSubtotal) {
    this.setState({ newItemSubtotal: newItemSubtotal })
  }

  toggleName() {
    this.setState({ showName: !this.state.showName })
  }

  toggleForecast() {
    if ( this.state.showForecast ) {
      this.setState({ showForecast: false })
    } else {
      this.setState({ loadingForecast: true }, () => {
        InventoryForecastService.query(this.props.buyer_order.id).then((result) => {
          this.setState({forecasts: result, loadingForecast: false, showForecast: true})
        })
      })
    }
  }

  calculateTotalDeposit() {
    const buyer_order = this.state.buyer_order
    const buyer_order_items = buyer_order.buyer_order_items
    const deposit_subtotals = buyer_order_items.map((buyer_order_item) => Number(buyer_order_item.deposit_subtotal))
    return deposit_subtotals.reduce((a,b) => a + b, 0)
  }

  calculateTotal() {
    const buyer_order = this.state.buyer_order
    const buyer_order_items = buyer_order.buyer_order_items
    const subtotals = buyer_order_items.map((buyer_order_item) => Number(buyer_order_item.subtotal))

    const deposit_subtotal = this.calculateTotalDeposit()
    const buyer_order_items_total = subtotals.reduce((a,b) => a + b, 0)

    return "" + (buyer_order_items_total + deposit_subtotal - buyer_order.overpayment_credit - Number(buyer_order.discount.replace('$', '')))
  }

  renderItemRows() {
    const productUnitCounts = _.countBy(this.state.buyer_order.buyer_order_items, (order_item) => {
      return order_item.product_unit_id
    })

    const items = _.filter(this.state.buyer_order.buyer_order_items, { 'add_after_subtotal': false })
    if (items.length === 0) {
      return (
        <div className="row">
          <div className="col-lg-12 text-center h3 py-3">
            There are no items in this order
          </div>
        </div>
      )
    } else {
      let sortedItems;
      if ( this.state.sortMethod == 'time-added') {
        sortedItems = _.sortBy(items, (item) => { return item.created_at })
      } else  {
        sortedItems = _.sortBy(items, (item) => { return item.product_unit.product_name })
      }
      return (
        sortedItems.map((order_item) => {
          var forecast_product_unit_id = order_item.product_unit.split_product_unit_id
          forecast_product_unit_id = forecast_product_unit_id || order_item.product_unit_id
          return (
            <SalesOrderItemEdit
              key={ order_item.id }
              isPurchaseOrder={this.props.isPurchaseOrder}
              onlyNegativeQuantity={ this.props.buyer_order.credit }
              sales_order_item={ order_item }
              showProducerName={ this.props.showProducerName }
              open_orders={ this.state.open_orders }
              updatedSalesOrderItem={ this.onItemUpdate }
              forecast={ this.state.forecasts[String(forecast_product_unit_id)] }
              changeOrder={ this.changeOrder }
              commonInternalNotes={ this.props.commonInternalNotes }
              orderProductUnitCount={ productUnitCounts[order_item.product_unit_id] }
              deletedSalesOrderItem={ this.onItemDestroy }
              showForecast={this.state.showForecast}
            />
          )
        })
      )
    }
  }

  renderAfterSubtotalRows() {
    const items = _.filter(this.state.buyer_order.buyer_order_items, { 'add_after_subtotal': true })
    return (
      items.map((order_item) => {
        return (
          <SalesOrderItemEdit
            key={ order_item.id }
            isPurchaseOrder={this.props.isPurchaseOrder}
            onlyNegativeQuantity={ this.props.buyer_order.credit }
            sales_order_item={ order_item }
            showProducerName={ this.props.showProducerName }
            open_orders={ this.props.open_orders }
            commonInternalNotes={ this.props.commonInternalNotes }
            updatedSalesOrderItem={ this.onItemUpdate }
            changeOrder={ this.changeOrder }
            deletedSalesOrderItem={ this.onItemDestroy }
          />
        )
      })
    )
  }

  renderSubtotalRow() {
    const after_items = _.filter(this.state.buyer_order.buyer_order_items, { 'add_after_subtotal': true })
    if (after_items.length == 0 && this.calculateTotalDeposit() == 0) { return }

    const items = _.filter(this.state.buyer_order.buyer_order_items, { 'add_after_subtotal': false })
    const subtotal = _.sumBy(items, (item) => Number(item.subtotal) )
    return (
      <div id='subtotal-row' className="order-row bg-light py-1">
        <div className="w-100 d-flex justify-content-end">
          <div className='w-100 text-right font-weight-bold'>Subtotal</div>
          <div className='subtotal-price-column font-weight-bold'>{ asCurrency(subtotal) }</div>
        </div>
      </div>
    )
  }

  renderOverpaymentRow() {
    const buyer_order = this.state.buyer_order
    if (buyer_order.overpayment_credit == 0) { return }
    return (
      <div className="order-row bg-light py-2">
        <div className="w-100 form-inline justify-content-end">
          <a href={`/admin/payments/${buyer_order.payment_id}`}>
            <strong className="mr-2">Overpayment Credit</strong>
          </a>
          <input
            type="text"
            disabled="disabled"
            value={ buyer_order.overpayment_credit }
            className="form-control text-right sig-3"
          />
        </div>
      </div>
    )
  }

  renderDiscountRow() {
    if ( this.state.buyer_order.discount == '$0.00' ) { return }
    return (
      <div className="order-row bg-light py-1">
        <div className="w-100 text-right">
          <strong className="mr-2">Discount</strong>
          <input
            type="text"
            name="discount"
            onChange={ this.onChangeBuyerOrder }
            value={ this.state.buyer_order.discount }
            className="form-control text-right sig-3"
          />
        </div>
      </div>
    )
  }

  renderDepositRow() {
    const total = this.calculateTotalDeposit()
    if ( total == 0 ) { return }
    return (
      <div id='deposit-row' className="order-row bg-light py-1">
        <div className="w-100 text-right">
          <div className="d-flex justify-content-end">
            <div className='w-100 text-right font-weight-bold'>Deposit</div>
            <div className='subtotal-price-column font-weight-bold'>{ asCurrency(total) }</div>
          </div>
        </div>
      </div>
    )
  }

  renderTotalRow() {
    const total = this.calculateTotal()
    return (
      <div id='total-row' className="order-row bg-light py-2">
        <div className="w-100 text-right">
          <div className="d-flex justify-content-end">
            <div className="w-100 text-right">
              <span className='h5 font-weight-bold'>Total</span>
            </div>
            <div className="subtotal-price-column">
              <span className='h5 font-weight-bold'>{ asCurrency(total) }</span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderName() {
    if (this.state.buyer_order.name.endsWith('.xml')) {
      return (<div>From filename: { this.state.buyer_order.name }</div>)
    }
    let purchaseOrderName
    let buttonText = "Set"
    if (this.state.showName) {
      buttonText = "Done"
      purchaseOrderName = (
        <input
          id="purchase_order_name"
          type="text"
          name="name"
          onChange={ this.onChangeBuyerOrder }
          value={ this.state.buyer_order.name }
          className="form-control"
        />
      )
    } else if (this.state.buyer_order.name) {
      buttonText = "Change"
      purchaseOrderName = (
        <span>{ this.state.buyer_order.name }</span>
      )
    }
    return (
      <div className="d-flex">
        <div className="mr-2 align-self-center">
          <b>Purchase Order ID for Invoice: </b>
        </div>
        <div className="align-self-center mr-2">
          { purchaseOrderName }
        </div>
        <div className="align-self-center">
          <button
            className="btn btn-sm btn-outline-secondary"
            onClick={this.toggleName} >
              { buttonText }
          </button>
        </div>
      </div>
    )
  }

  renderButton() {
    const warningClass = this.props.contactCount === 0 ? ' badge-warning' : ''
    if (this.state.buyer_order.credit) {
      return (
        <div className="btn-group">
          <button type="submit" name="commit" value="Complete" className="btn btn-lg btn-primary" data-disable-with="Complete" >
            Complete
            <span className={`ml-2 badge border${ warningClass }`}>
              { this.props.contactCount }
              <span className="fa fa-paper-plane ml-1"></span>
            </span>
          </button>
          <button className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split" aria-expanded="false" aria-haspopup="true" data-toggle="dropdown" type="button">
            <span className="sr-only">Toggle Dropdown</span>
          </button>
          <div className="dropdown-menu dropdown-menu-left p-0 border-0">
            <input type="submit" name="commit" value="Complete and Skip Email" className="btn btn-lg btn-block btn-outline-secondary" />
          </div>
        </div>
      )
    } else {
      return (
        <div className="btn-group">
          <button type="submit" name="commit" value="Submit" className="btn btn-lg btn-primary" data-disable-with="Submit" >
            Submit
            <span className={`ml-2 badge border${ warningClass }`}>
              { this.props.contactCount }
              <span className="fa fa-paper-plane ml-1"></span>
            </span>
          </button>
          <button className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split" aria-expanded="false" aria-haspopup="true" data-toggle="dropdown" type="button">
            <span className="sr-only">Toggle Dropdown</span>
          </button>
          <div className="dropdown-menu dropdown-menu-left p-0 border-0">
            <input type="submit" name="commit" value="Submit and Skip Email" className="btn btn-lg btn-block btn-outline-secondary" />
          </div>
        </div>
      )
    }
  }

  renderSubmitButton() {
    const buyer_order = this.state.buyer_order
    if (buyer_order.status != "draft" || (buyer_order.buyer_order_items.length == 0 && this.calculateTotal() == 0)) { return }
    return (
      <div className="mt-2">
        <form
          action={`/admin/${ buyer_order.credit ? 'credit_memos': 'sales_orders' }/${buyer_order.id}`}
          acceptCharset="UTF-8"
          method="post"
        >
          <input name="utf8" type="hidden" value="✓" />
          <input type="hidden" name="_method" value="patch" />
          <input type="hidden" name="authenticity_token" value={this.props.csrf_token} />
          <input type="hidden" value={buyer_order.id} name="buyer_order[id]" id="buyer_order_id" />
          { this.renderButton() }
        </form>
      </div>
    )
  }

  renderForecastButton(){
    if ( this.props.isPurchaseOrder ) { return }

    const forecastActive = this.state.showForecast ? ' active' : ''
    const icon = this.state.loadingForecast ? 'fa fa-spinner fa-pulse' : 'fa fa-chart-bar'
    return (
      <span className='ml-4'>
        <button className={`btn btn-sm btn-outline-secondary${forecastActive}`} onClick={this.toggleForecast}>
          <span className={ icon } ></span>
        </button>
      </span>
    )
  }

  renderSortButtons() {
    const timeActive = this.state.sortMethod == 'time-added' ? ' active' : ''
    const nameActive = this.state.sortMethod == 'name' ? ' active' : ''
    return (
      <span className='ml-2'>
        <span className='mr-1'>
          <button className={`btn btn-sm btn-outline-secondary${timeActive}`} onClick={() => this.changeSortMethod('time-added') }>
            <span className='far fa-clock'></span>
          </button>
        </span>
        <span>
          <button className={`btn btn-sm btn-outline-secondary${nameActive}`} onClick={() => this.changeSortMethod('name') }>
            <span className='fas fa-sort-alpha-down'></span>
          </button>
        </span>
      </span>
    )
  }

  renderOrderButtons() {
    if (!this.state.open_orders) { return }
    if (this.state.open_orders.length == 0) {
      return <button className='btn btn-sm btn-outline-secondary' disabled >No other orders</button>
    } else if (this.state.open_orders.length == 1 ) {
      const path = '/admin/sales_orders/' + this.state.open_orders[0].id + '/edit'
      return (
        <span className='small text-nowrap'>
          <span className='d-none d-md-inline'>Also open:</span>
          <a className='btn btn-sm btn-outline-secondary' href={path}><OrderInfo order={this.state.open_orders[0]}/></a>
        </span>
      )
    } else {
      const orderLinks = this.state.open_orders.map((order) => {
        const path = '/admin/sales_orders/' + order.id + '/edit'
        return (
          <a key={order.id} className="dropdown-item py-2" href={path}>
            <OrderInfo order={order}/>
          </a>
        )
      })
      return (
        <div className="dropdown">
          <button className="btn btn-outline-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span className='badge badge-secondary mr-1'>{this.state.open_orders.length}</span>
            Also open
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            { orderLinks }
          </div>
        </div>
      )
    }
  }

  render() {
    return (
      <div id='sales-order-edit' className='list-group position-relative'>
          <SalesOrderItemNew
            sales_order={ this.props.buyer_order }
            createdSalesOrderItem={ this.onItemCreate }
            onSubtotalUpdate={ this.onNewItemSubtotalUpdate }
          />
        <div id='item-header-row' className='order-row py-1 align-items-baseline bg-light'>
          <div className='toggle-more-column'>
            <button className='btn btn-sm btn-white disabled'><span className='fa fa-ellipsis-v text-light'></span></button>
          </div>
          { /*<div className="d-flex flex-fill no-gutters py-2 pr-1 align-items-baseline font-weight-bold">*/ }
          <div className='quantity-column text-center font-weight-bold'>Qty</div>
          <div className='item-column d-flex justify-content-between flex-wrap'>
            <div className='text-nowrap font-weight-bold'>
              Item
              { this.renderSortButtons() }
              { this.renderForecastButton() }
            </div>
            <div>
              { this.renderOrderButtons() }
            </div>
          </div>
          <div className='d-flex no-gutters text-nowrap font-weight-bold'>
            <div className='unit-price-column'>Unit Price</div>
            <div className='subtotal-price-column'>Subtotal</div>
          </div>
        </div>
        <div>
          { this.renderItemRows() }
          { this.renderSubtotalRow() }
          { this.renderDepositRow() }
          { this.renderDiscountRow() }
          { this.renderOverpaymentRow() }
          { this.renderAfterSubtotalRows() }
          { this.renderTotalRow() }
        </div>
        <div className="form-group row mt-2">
          <div className="col-sm-6 mb-2">
            <label htmlFor="note_from_buyer">Note from Buyer</label>
            <textarea
              id="note_from_buyer"
              name="note_from_buyer"
              placeholder='Add a note from the buyer...'
              onChange={ this.onChangeBuyerOrder }
              value={ this.state.buyer_order.note_from_buyer || "" }
              className="form-control"
            />
          </div>
          <div className="col-sm-6 mb-2">
            <label htmlFor="note_from_seller">Note from Seller</label>
            <textarea
              id="note_from_seller"
              name="note_from_seller"
              placeholder='Add a note from the seller...'
              onChange={ this.onChangeBuyerOrder }
              value={ this.state.buyer_order.note_from_seller || "" }
              className="form-control"
            />
          </div>
        </div>
        { this.renderName() }
        { this.renderSubmitButton() }
        <ToastContainer
          position="bottom-left"
          type="default"
          autoClose={2300}
          className="toastify"
          bodyClassName="toast-notification"
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          pauseOnHover={false}
        />
      </div>
    )
  }
}
export default SalesOrderEdit