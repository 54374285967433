import React from "react"
import { isTomorrow } from "../../services/Utils"

export class DeliveryDatePicker extends React.Component {
  constructor(props) {
    super(props)
  }

  formatDate(date_string) {
    if (!date_string) { return 'Please schedule for me' }
    if (isTomorrow(date_string)) { return 'Tomorrow' }

    let date = new Date(date_string + 'T12:00:00Z')
    return date.toLocaleDateString('en-US', { weekday: 'long', month: 'short', day: 'numeric' })
  }

  renderButtons() {
    return (
      Object.keys(this.props.available_days).map((date) => {
        const on = this.props.available_days[date]
        return (
          <div className='calendar-day' key={date}>
            { on ? this.renderActiveButton(date) : this.renderDisabledButton() }
          </div>
        )
      })
    )
  }

  monthName(string_date) {
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
    const index = new Number(string_date.slice(5, 7)) - 1
    return months[index]
  }

  renderDisabledButton() {
    return (
      <button className='btn btn-block h-100 px-0' disabled='disabled'>
        <span className='fa fa-times fa-sm'></span>
      </button>
    )
  }

  renderActiveButton(date) {
    return (
      <button className='btn btn-block btn-outline-primary px-0' value={date} onClick={ this.props.onChange }>
        <div className='small'>
          { this.monthName(date) }
        </div>
        <div>
          { Number(date.slice(8, 10)) }
        </div>
      </button>
    )
  }

  renderDropdown() {
    return (
      <div className='dropdown'>
        <button type='button' className='btn btn-sm btn-outline-dark dropdown-toggle btn-block' data-toggle='dropdown'>
          { this.formatDate(this.props.requested_on) }
        </button>
        <div className='dropdown-menu p-2 shadow' style={{ zIndex: 1040, width: '350px'}}>
          <div className='row no-gutters'>
            <div className='col'>
              <span className='text-muted small font-weight-bold'>CALENDAR</span>
            </div>
          </div>
          <div className='row no-gutters font-weight-bold'>
            <div className='col text-center'>Su</div>
            <div className='col text-center'>M</div>
            <div className='col text-center'>Tu</div>
            <div className='col text-center'>W</div>
            <div className='col text-center'>Th</div>
            <div className='col text-center'>F</div>
            <div className='col text-center'>Sa</div>
          </div>
          <div className='row no-gutters'>
            { this.renderButtons() }
          </div>
          <div className='row no-gutters mt-3'>
            <button className='btn btn-block btn-outline-primary px-0' value='' onClick={ this.props.onChange }>
              <div className='small'>
                Please schedule for me
              </div>
            </button>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div id='delivery-date-picker'>
        <label className='mb-1 mr-1' htmlFor='requested-select'>Delivery Requested For:</label>
        { this.renderDropdown() }
      </div>
    )
  }
}
