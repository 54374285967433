import React from "react"
import Product from "./Product"
import ahoy from "ahoy.js";
import { SearchBar } from "./SearchBar"
import { ProductGallery } from "./ProductGallery"
import { DeliveryDatePicker } from "./DeliveryDatePicker"
import { ToastContainer } from 'react-toastify'
import { isTomorrow } from "../../services/Utils"
import classNames from "classnames"
import _ from "lodash"

var catalog_cache = {
  products: [],
  meta: null
}

export class ProductCatalog extends React.Component {
  constructor(props) {
    super(props)
  }

  render () {
    return (
      <div>
        <Catalog {...this.props} fetcher={this.props.fetcher}/>
        <ToastContainer
          position="bottom-left"
          type="default"
          autoClose={2300}
          className="toastify"
          bodyClassName="toast-notification"
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          pauseOnHover={false}
        />
      </div>
    )
  }
}

export class Catalog extends React.Component {
  constructor(props) {
    super(props)

    this.addItemToCart = this.addItemToCart.bind(this)
    this.changeLayoutToList = this.changeLayoutToList.bind(this)
    this.changeLayoutToGrid = this.changeLayoutToGrid.bind(this)
    this.openGallery = this.openGallery.bind(this)
    this.changeGalleryIndex = this.changeGalleryIndex.bind(this)
    this.closeGallery = this.closeGallery.bind(this)
    this.requestProductDetail = this.requestProductDetail.bind(this)
    this.toggleLocalOnly = this.toggleLocalOnly.bind(this)

    this.setSort = this.setSort.bind(this)
    this.toggleFilters = this.toggleFilters.bind(this)
    this.setProducer = this.setProducer.bind(this)
    this.setRequestedOn = this.setRequestedOn.bind(this)

    this.toggleIsStocked = this.toggleIsStocked.bind(this)

    this.changeLayoutToListNoImages = this.changeLayoutToListNoImages.bind(this)
    this.search = this.search.bind(this)
    this.fetchProducts = _.debounce(this.fetchProducts, 500)

    this.grid = "grid"
    this.list = "list"
    this.listNoImg = "list-no-img"

    let isStocked = false

    if (isTomorrow(this.props.requestedOn)) {
      isStocked = true
    }

    this.state = {
      showGallery: false,
      layout: this.grid,
      pageCount: 1,
      currentPage: 1,
      batchSize: 24,
      requestedOn: this.props.requestedOn || '',
      filters: {
        search: '',
        isStocked: isStocked,
        sortBy: 'popularity',
        categories: [],
        collection: null,
        subcollection: null,
        collections: [],
        producers: (this.props.selectedProducerId ? [Number(this.props.selectedProducerId)] : []),
        localOnly: false,
        empty: true
      },
      productCount: this.props.preloaded_products.length,
      loadingResults: false,
      pagedProducts: {}
    }
  }

  initialProductRequest() {
    let params = this.buildParams()
    const page = 1

    this.props
      .fetcher(page, this.state.batchSize, params || {})
      .then(productBatch => {
        const productCount = productBatch.length
        if (productCount < this.state.batchSize) {
          this.setState({ pageCount: 1, productCount: productCount })
        } else {
          this.requestProductCount(params)
        }
        this.loadProducts(productBatch, page)
      })
  }

  addItemToCart(buyer_order_item_params, name, src) {
    ahoy.track("Add to Cart", { order_item: buyer_order_item_params, filters: this.state.filters })
    this.props.addItem(buyer_order_item_params, name, src)
  }

  openGallery(index) {
    ahoy.track("Open Gallery", { index: index, filters: this.state.filters })
    this.requestProductDetail(index)
    this.setState({showGallery: true, index: index})
  }

  changeGalleryIndex(index) {
    const currentPageProducts = this.state.pagedProducts[this.state.currentPage] || []
    const modulus = currentPageProducts.length
    const newIndex = ((index % modulus) + modulus) % modulus // JS positive modulus
    this.requestProductDetail(newIndex)
    this.setState({index: newIndex})
  }

  closeGallery() {
    this.setState({showGallery: false})
  }

  requestProductDetail(index) {
    const pagedProducts = this.state.pagedProducts
    const products = pagedProducts[this.state.currentPage] || []
    const product = products[index]
    this.props.fetcher(1, 10, { productSlug: product.product_slug }).then(
      (response) => {
        pagedProducts[this.state.currentPage][index].otherProducts = response
        this.setState({ pagedProducts: pagedProducts })
      }
    )
  }

  requestProductCount(searchParams) {
    searchParams.count = true
    this.props.fetcher(null, null, searchParams).then(
      (response) => {
        const newPageCount = Math.ceil(response.count / this.state.batchSize)
        this.setState({ pageCount: newPageCount, productCount: response.count })
      }
    )
  }

  buildParams() {
    let params = {}
    const filters = this.state.filters
    if ( this.props.buyer_id )
      params.buyerId = this.props.buyer_id
    params.text = filters.search
    params.categories = filters.categories
    params.collections = filters.collections
    params.producers = filters.producers
    params.localOnly = filters.localOnly
    params.isStocked = filters.isStocked
    params.requestedOn = filters.requestedOn
    params.sortBy = filters.sortBy

    return params
  }

  requestProducts(page) {
    let params = this.buildParams()
    page = page || 1

    this.props
      .fetcher(page, this.state.batchSize, params)
      .then(productBatch => {
        this.loadProducts(productBatch, page)
      })
  }

  componentDidMount() {
    this.loadProducts(this.props.preloaded_products, 1)
    if (this.props.startTerm) {
      this.search(this.props.startTerm)
    }
  }

  fetchProducts() {
    if (this.validSearch()) {
      this.setState({ pageCount: 0, currentPage: 1, pagedProducts: {}, loadingResults: true }, () => {
        this.initialProductRequest()
      })
    } else if (!this.state.filters.search.text) {
      this.setState({ pagedProducts: {}, currentPage: 1, pageCount: 1 }, () => { this.loadProducts(this.props.preloaded_products, 1) })
    }
  }

  // load an array of products
  loadProducts(addedProducts, page) {
    let pagedProducts = this.state.pagedProducts
    pagedProducts[page] = addedProducts
    this.setState({ pagedProducts: pagedProducts, loadingResults: false, currentPage: page })
  }

  changeSearchTerm(newSearchTerm) {
    let filters = this.state.filters
    const previousSearchTerm = filters.search
    filters.search = newSearchTerm
    this.setState({ filters: filters })
    if ( newSearchTerm.trim() == previousSearchTerm.trim() ){ return }
    // wait until the user pauses for more than 500 ms
    setTimeout(() => {
      this.search(newSearchTerm.trim())
    }, 500)
  }

  search(term) {
    let filters = this.state.filters
    if (term == '') {
      this.setState({ pagedProducts: {}, currentPage: 1, pageCount: 1 }, () => { this.loadProducts(this.props.preloaded_products, 1) })
    } else {
      filters.categories = []
      filters.collections = []
      filters.producers = []
      filters.collection = null
      filters.subcollection = null
      this.setState({ showFilters: false,
                      loadingResults: true,
                      filters: filters }, this.fetchProducts)
    }
  }

  validSearch() {
    return (
      this.state.filters.localOnly ||
      this.state.filters.isStocked ||
      this.state.filters.search.length > 2 ||
        this.state.filters.categories.length > 0 ||
        this.state.filters.collections.length > 0 ||
        this.state.filters.producers.length > 0
    )
  }

  changePage(event) {
    const desiredPage = event.target.value

    if (this.state.currentPage !== desiredPage) {
      if (!this.state.pagedProducts[desiredPage]) {
        this.requestProducts(desiredPage)
      } else {
        this.setState({ currentPage: desiredPage })
      }
    }
    var element = document.getElementById('products');
    var headerOffset = 90;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({top: offsetPosition, behavior: "smooth" })
  }

  toggleFilters() {
    this.setState({ showFilters: !this.state.showFilters })
  }

  changeFilters(filters) {
    this.setState({ filters: filters, loadingResults: true }, this.fetchProducts)
  }

  setCategory(category) {
    let filters = this.state.filters
    if (filters.categories.length === 1 && filters.categories[0] == category) {
      filters.categories = []
    } else {
      filters.categories = [category]
    }


    this.changeFilters(filters)
  }

  setCollection(collectionSlug) {
    let filters = this.state.filters
    if (this.state.filters.collection == collectionSlug) {
      filters.collection = null
      filters.subcollection = null
      filters.collections = []
      filters.search = ''
    } else {
      filters.collection = collectionSlug
      filters.subcollection = null
      filters.collections = [collectionSlug]
      filters.search = ''
    }
    this.changeFilters(filters)
  }

  setSubcollection(subcollection, collection) {
    let filters = this.state.filters
    filters.subcollection = subcollection
    if (collection)
      filters.collection = collection
    filters.search = ''
    filters.collections = [subcollection]
    this.changeFilters(filters)
  }

  setSort(event) {
    ahoy.track("Catalog Sort", { sortBy: event.target.value })

    let filters = this.state.filters
    filters.sortBy = event.target.value

    this.changeFilters(filters)
  }

  setProducer(event) {
    let filters = this.state.filters

    filters.producers = event.target.value ? [new Number(event.target.value)] : []

    this.changeFilters(filters)
  }

  setRequestedOn(event) {
    this.setState({ requestedOn: event.currentTarget.value })
    this.props.updateRequestedOn(event.currentTarget.value)

    const newIsStocked = isTomorrow(event.currentTarget.value)
    let filters = this.state.filters
    filters.isStocked = newIsStocked
    this.changeFilters(filters)
  }

  toggleLocalOnly() {
    let filters = this.state.filters
    filters.localOnly = !filters.localOnly

    this.changeFilters(filters)
  }

  toggleIsStocked() {
    let filters = this.state.filters
    filters.isStocked = !filters.isStocked

    this.changeFilters(filters)
  }

  renderPageTabs() {
    var tabs = []

    const starting = this.state.currentPage < 5 ? 0 : this.state.currentPage - 3
    const len = Math.min(starting + 5, this.state.pageCount)

    if (starting > 0) {
      tabs.push(
        <div className="catalog-page-tab" key={"pageTab_1"}>
          <button
            className="btn catalog-page-tab-button"
            onClick={this.changePage.bind(this)}
            value={1}
          >
            First Page
          </button>
        </div>
      )
    }

    for (var i = starting; i < len; i++) {
      const val = i + 1

      tabs.push(
        <div className="catalog-page-tab" key={"pageTab_" + i}>
          <button
            className={
              "btn" +
              (this.state.currentPage == val
                ? " btn-success"
                : " catalog-page-tab-button")
            }
            onClick={this.changePage.bind(this)}
            value={val}
          >
            {val}
          </button>
        </div>
      )
    }

    if (len < this.state.pageCount) {
      tabs.push(
        <div className="catalog-page-tab" key={"pageTab_" + this.state.pageCount}>
          <button
            className="btn catalog-page-tab-button"
            onClick={this.changePage.bind(this)}
            value={this.state.pageCount}
          >
            Last Page
          </button>
        </div>
      )
    }

    return <div id="catalog-page-tabs">{tabs}</div>
  }

  changeLayoutToList() {
    ahoy.track("Layout Change", { language: "JavaScript", layout: 'list' });
    this.setState({ layout: this.list })
  }

  changeLayoutToGrid() {
    ahoy.track("Layout Change", { language: "JavaScript", layout: 'grid' });
    this.setState({ layout: this.grid })
  }

  changeLayoutToListNoImages() {
    ahoy.track("Layout Change", { language: "JavaScript", layout: 'list no images' });
    this.setState({ layout: this.listNoImg })
  }

  renderLayoutButtons() {
    var layout = this.state.layout

    const changeLayoutGridButtonClass = classNames({
      "btn": true,
      "btn-sm": true,
      "mr-2": true,
      "btn-success": layout === this.grid,
      "btn-secondary": layout !== this.grid
    })

    const changeLayoutListButtonClass = classNames({
      "btn": true,
      "btn-sm": true,
      "mr-2": true,
      "btn-success": layout === this.list,
      "btn-secondary": layout !== this.list
    })

    const changeLayoutListNoImagesButtonClass = classNames({
      "btn": true,
      "btn-sm": true,
      "mr-2": true,
      "btn-success": layout === this.listNoImg,
      "btn-secondary": layout !== this.listNoImg
    })

    return (
      <div className="view-options" role="toolbar">
        <div
          onClick={ this.changeLayoutToGrid }
          className={ changeLayoutGridButtonClass }
          role="group"
        >
          <span className="fa fa-th-large" aria-hidden="true" />
        </div>
        <div
          onClick={ this.changeLayoutToList }
          className={ changeLayoutListButtonClass }
          role="group"
        >
          <span className="fa fa-th-list" aria-hidden="true" />
        </div>
        <div id="layout-list-no-images"
          onClick={ this.changeLayoutToListNoImages }
          className={ changeLayoutListNoImagesButtonClass }
          role="group"
        >
          <i className="fa fa-bars" aria-hidden="true" />
        </div>
      </div>
    )
  }

  renderProductList(pagedProducts) {
    let index = 0
    return pagedProducts.map(
      (product) => {
        return (
          <Product
            product={product}
            key={product.product_unit_id}
            openGallery={this.openGallery}
            cartEnabled={this.props.cartEnabled}
            showPricing={this.props.showPricing}
            showImage={this.state.layout != this.listNoImg}
            addItem={this.addItemToCart}
            showProducer={this.props.showProducer}
            type={this.state.layout === this.grid ? this.grid : this.list }
            index={index++}
          />
        )
      }
    )
  }

  renderNoResults() {
    const loadedPageCount = Object.keys(this.state.pagedProducts).length
    if (this.state.loadingResults || loadedPageCount !== 0) { return }
    return (
      <div id="no-results">No Results</div>
    )
  }

  renderSearchNotifier() {
    if ( this.validSearch() && !this.state.loadingResults ) {
      const filters = this.state.filters

      const category_list = filters.categories.map(
        (id) => { return _.find(this.props.categories, { id: id }).name}
      )
      let collectionName;
      if ( this.state.filters.subcollection ) {
        var collection = _.find(this.props.collections, { slug: this.state.filters.collection })
        collectionName = _.find(collection.current_children, { slug: this.state.filters.subcollection }).name
      } else if ( this.state.filters.collection ) {
        collectionName = _.find(this.props.collections, { slug: this.state.filters.collection }).name
      }

      const producer_list = filters.producers.map(
        (id) => { return _.find(this.props.producers, { id: id }).name }
      )

      const terms = category_list.concat(producer_list)
      if (this.state.filters.search) {
        terms.push(this.state.filters.search)
      }
      if (collectionName) {
        terms.push(collectionName)
      }


      if (filters.localOnly) {
        terms.push("Local Only")
      }
      if (filters.isStocked) {
        const today = new Date()
        const tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate() + 1)
        let day = tomorrow.toLocaleDateString('en-US', { weekday: 'long' });
        if ( day == 'Sunday' ) { day = 'Monday' }
        terms.push(`Delivers ${ day }`)
      }
      const productCount = this.state.productCount

      const message = terms.join('", "')
      const start = 1 + (this.state.currentPage - 1) * this.state.batchSize
      const currentEnd = Math.min(start + this.state.batchSize - 1, productCount)
      const count = `${start} to ${currentEnd}`

      let countMsg
      if (productCount == 0) {
        countMsg = 'There are no'
      } else if (this.state.currentPage == 1 && productCount == currentEnd) {
        countMsg = `Showing all ${productCount}`
      } else {
        countMsg = `Showing ${count} of ${productCount}`
      }

      return (
        <div className="search-notifier">
          {`${countMsg} products matching "${message}"`}
        </div>
      )
    } else if (!this.validSearch() && this.state.filters.search != "") {
      return (<div className="search-notifier text-danger">Please enter at least 3 characters</div>)
    } else {
      return (<div className="search-notifier"></div>)
    }
  }

  renderWaitSpinner() {
    if (this.state.loadingResults && this.validSearch() ) {
      return (
        <div className="text-center">
          <i
            className="fa fa-spinner fa-spin fa-pulse fa-3x"
            aria-hidden="true"
          />
        </div>
      )
    }
  }

  renderOrderDeadlineText() {
    return (
      <div className="text-center text-danger lead font-weight-bold">{this.props.seller.ordering_message}</div>
    )
  }

  renderSellerLogo() {
    const seller = this.props.seller
    let logoContent = (<h3>{ seller.name }</h3>)
    if (seller.logo_url) {
      logoContent = (<img className='img-fluid' src={ seller.logo_url } />)
    }
    return (
      <div>
        { logoContent }
      </div>
    )
  }

  renderCategoryToggles() {
    if (this.props.categories.length < 2) { return }
    return this.props.categories.map( (category) => {
      var classes = classNames({
        'category-clicker': true,
        "active": this.state.filters.categories[0] == category.id
      })

      return (
        <div className={classes} key={category.id} onClick={() => this.setCategory(category.id) }>
          <span>{ category.name }</span>
        </div>
      )
    })
  }

  renderCollections() {
    return (
      <div>
        <div>
          { this.renderCollectionToggles() }
        </div>
      </div>
    )
  }

  renderCollectionToggles() {
    if (this.props.collections.length < 2) { return }
    return this.props.collections.map( (collection) => {
      var collectionClasses = classNames({
        'collection-clicker': true,
        "active": this.state.filters.collection == collection.slug
      })

      var children = []
      if ( collection.slug == this.state.filters.collection ) {
        children = collection.current_children.map( (child_collection) => {
          var subcollectionClasses = classNames({
            'ml-3': true,
            'subcollection-clicker': true,
            "active": this.state.filters.subcollection == child_collection.slug
          })
          return (
            <div key={ child_collection.slug } className={ subcollectionClasses } onClick={() => this.setSubcollection(child_collection.slug) }>
              { child_collection.name }
            </div>
          )
        })
      }

      return (
        <div key={collection.slug}>
          <div className={ collectionClasses } onClick={() => this.setCollection(collection.slug) }>
            <span>{ collection.name }</span>
            <span className='ml-1 dropdown-toggle'></span>
          </div>
          { children }
        </div>
      )
    })
  }

  renderIsStocked() {
    if ( !this.state.filters.isStocked ) { return  }

    return (
      <div className='form-check ml-3 mb-2'>
        <input disabled='disabled' checked={ this.state.filters.isStocked } className='form-check-input' type='checkbox' id='search-is-stocked' onChange={ this.toggleIsStocked }/>
        <label className='form-check-label' htmlFor='search-is-stocked'>In Stock Only</label>
      </div>
    )
  }

  renderProducers() {
    const producerOptions = this.props.producers.map((producer) => {
      return <option key={producer.id} value={producer.id}>{ `${producer.name} ${producer.city_state}` }</option>
    })

    return (
      <div className='mt-3'>
        <label className='mb-1' htmlFor='producer-select'>Producers</label>
        <select id='producer-select' className='form-control form-control-sm' onChange={this.setProducer} value={this.state.filters.producers[0] || ''}>
          <option value=''>-- All Producers --</option>
          { producerOptions }
        </select>
      </div>
    )
  }

  renderRequestFor() {
    const dates_on = Object.values(this.props.requested_on_options || {})
    const count_on = dates_on.filter((element) => element === true).length
    if ( count_on == 0 ) { return }

    return <DeliveryDatePicker available_days={this.props.requested_on_options}
                               requested_on={this.state.requestedOn}
                               onChange={this.setRequestedOn} />
  }

  renderDownload() {
    if ( !this.props.showPricing ) { return }

    return (
      <div className='mt-4 d-none d-md-block'>
        <a href={`/admin/seller/${ this.props.seller.slug }/catalog_download.csv`}>
          <span className='fa fa-download mr-1'></span>
          Download Catalog
        </a>
      </div>
    )
  }

  renderLocalOnlyToggle() {
    return (
      <div className='form-check mt-3'>
        <input checked={ this.state.filters.localOnly } className='form-check-input' type='checkbox' id='search-local-only' onChange={ this.toggleLocalOnly }/>
        <label className='form-check-label' htmlFor='search-local-only'>Local Only</label>
      </div>
    )
  }

  renderSort() {
    if ( !this.validSearch()) { return }
    return (
      <select className='form-control form-control-sm' onChange={this.setSort} selected={this.state.filters.sortBy}>
        <option value='popularity'>Popularity</option>
        <option value='product_az'>Product Name, A-Z</option>
        <option value='unit_az'>Unit Size, A-Z</option>
        <option value='producer_az'>Producer, A-Z</option>
        <option value='price_hl'>Price, High-Low</option>
        <option value='price_lh'>Price, Low-High</option>
      </select>
    )
  }

  render() {
    const layout = this.state.layout
    const pagedProducts = this.state.pagedProducts[this.state.currentPage] || []

    var sidebarToggleClass = classNames({
      'd-md-block py-md-3 pb-3': true,
      'd-none': !this.state.showFilters
    })

    return (
      <div id="catalog">
        <ProductGallery
          index={this.state.index}
          visible={this.state.showGallery}
          cartEnabled={this.props.cartEnabled}
          requestProductDetail={this.requestProductDetail}
          closeGallery={this.closeGallery}
          changeGalleryIndex={this.changeGalleryIndex}
          addItem={this.addItemToCart}
          product_unit={pagedProducts[this.state.index]}
          showPricing={this.props.showPricing}
        />

        <div id="catalog-header" className='row justify-content-center justify-content-md-start'>
          <div id="logo" className='col-md-3 col-4 d-flex'>
            { this.renderSellerLogo() }
          </div>
          <div id="search" className='col-md-9 col-12'>
            <div>
              { this.renderOrderDeadlineText() }
            </div>
            <div id="search-row" className='d-flex'>
              <div id="search-box" className='align-self-center flex-fill'>
                <SearchBar collections={this.props.collections}
                           setSubcollection={this.setSubcollection.bind(this)}
                           setCollection={this.setCollection.bind(this)}
                           value={this.state.filters.search}
                           onChange={this.changeSearchTerm.bind(this)} />
              </div>
              <div id="cart-icon"> {this.props.cart} </div>
            </div>
            { this.renderSearchNotifier() }
          </div>
        </div>

        <div id="catalog-body" className='row'>
          <div id='sidebar' className='col-md-3'>
            <div className="mb-3">
              { this.props.collections.length ? this.renderRequestFor() : null  }
              { this.props.collections.length ? this.renderIsStocked() : null }
              { this.props.collections.length ? this.renderLocalOnlyToggle() : null }
            </div>
            <div>
              { this.props.collections.length ? null : this.renderRequestFor() }
              { this.props.collections.length ? null : this.renderIsStocked() }
              { this.props.collections.length ? null : this.renderLocalOnlyToggle() }
            </div>
            <div className={sidebarToggleClass}>
              <div id="category-toggles">
                { this.renderCategoryToggles() }
                { this.renderCollections() }
              </div>
              <div id='toggles'>
                { this.renderProducers() }
                { this.renderDownload() }
              </div>
            </div>
          </div>

          <div id="products-body" className='col-md-9 col-12'>
            <div id='products-header' >
              <div>
                { this.renderNoResults() }
              </div>

              <div className='d-md-none mb-2'>
                <button className='btn border-dark btn-block' onClick={ this.toggleFilters.bind(this)} >
                  Collections and Filters
                  <span className='ml-1 dropdown-toggle'></span>
                </button>
              </div>
              <div className='d-flex justify-content-between'>
                <div className='d-md-block' id="layout-options">
                  { this.renderLayoutButtons() }
                </div>
                <div>
                  { this.renderSort() }
                </div>
              </div>
            </div>
            <div id="products">
              { this.renderProductList(pagedProducts) }
            </div>
            { this.renderWaitSpinner() }
            { this.renderPageTabs() }
          </div>
        </div>
      </div>
    )
  }
}
