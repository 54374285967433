import React from "react"
import { AddToCart } from "./AddToCart"
import { ProductUnitPrice } from "./ProductUnitPrice"
import { ProductUnitDeposit } from "./ProductUnitDeposit"

export default class Product extends React.Component  {
  constructor(props) {
    super(props)
    this.state = { expanded: props.expanded }
  }

  toggle() {
    this.props.openGallery(this.props.index)
    return
  }

  showDiscountIndicator() {
    const product = this.props.product
    return product.discount_price && Number(product.discount_price) < Number(product.price)
  }

  renderProducerCity() {
    const product = this.props.product

    if (product.city && product.state) {
      return (
        <div className="producer-city-state">
          { product.city }, { product.state }
        </div>
      )
    } else {
      return (
        <div className="producer-city-state text-white">
          Farther Afield
        </div>
      )
    }
  }

  renderProducerName() {
    if (!this.props.showProducer) { return }
    const product = this.props.product
    return (
      <div className="product-producer">
        { product.producer_name }
      </div>
    )
  }

  renderProductImage() {
    if (!this.props.showImage) { return }
    const product = this.props.product
    var unavailableClass = product.unavailable_type == 'temporary' ? ' unavailable' : ''
    var unavailableClass = product.unavailable_type == 'preorder' ? ' preorder' : ''
    var discountClass = this.showDiscountIndicator() ? ' discount' : ''

    var image = this.props.product.product_photo.small_url
    return (
      <div className={`product-image list image-container${ unavailableClass }${discountClass}`}>
        <img src={image} width="100%" />
      </div>
    )
  }

  renderNote() {
    if (!this.props.product.note) { return }

    return (
      <div className="note mt-1 mb-2">
        <span className='fa fa-info-circle mr-1'/>
        { this.props.product.note}
      </div>
    )
  }


  renderListProduct() {
    const product = this.props.product

    return (
      <div className="product-card list">
        <div className="product-item list ">
          <div className='d-flex'>
            <div> { this.renderProductImage() } </div>
            <div className='row w-100'>
              <div className='col-12 col-md-8 col-lg-9' onClick={this.toggle.bind(this)}>
                <div className="product-details list cursor-pointer">
                  <div className='producer-details d-sm-flex mb-1'>
                    { this.renderProducerName() }
                    { this.renderProducerCity() }
                  </div>
                  <div className="product-name">
                    { product.name }
                  </div>
                  <div className='product-unit'>
                    {this.props.product.unit}
                  </div>
                  <div className='h5 mt-2'>
                    <ProductUnitPrice product_unit={ product } showPricing={ this.props.showPricing } />
                  </div>
                  { this.renderNote() }
                  <div className="product-description">
                    <em>{ product.description }</em>
                  </div>
                </div>
              </div>
              <div className='col-3 col-md-4 col-lg-3'>
                <div className="mt-2">
                  <AddToCart
                    product_unit={ product }
                    seller_id={ product.seller_id }
                    showPricing={ this.props.showPricing }
                    cartEnabled={ this.props.cartEnabled }
                    addItem={ this.props.addItem }
                    src='catalog_list'
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderGridProduct() {
    var product = this.props.product
    var product_unit = this.props.product
    var image = this.props.product.product_photo.small_url
    var unavailableClass = product.unavailable_type == 'temporary' ? ' unavailable' : ''
    unavailableClass = product.unavailable_type == 'preorder' ? ' preorder' : ''
    var discountClass = this.showDiscountIndicator() ? ' discount' : ''

    return (
      <div className="product-card grid">
        <div className="product-item grid drop-shadow-hover d-flex flex-column justify-content-between">
          <div className='flex-fill cursor-pointer' onClick={this.toggle.bind(this)}>
          <div className={ `product-image grid image-container${unavailableClass}${discountClass}` }>
              <img src={image} width="100%" />
            </div>
            <div
              className="product-details grid"
              onClick={this.toggle.bind(this)}
            >
              <div className='producer-details mb-1'>
                { this.renderProducerName() }
                { this.renderProducerCity() }
              </div>
              <div className="product-name grid">
                {this.props.product.name}
              </div>
              <div className='product-unit'>
                {this.props.product.unit}
              </div>
              <div className='h5 mt-2'>
                <ProductUnitPrice product_unit={ product } showPricing={ this.props.showPricing } />
              </div>
              <ProductUnitDeposit product_unit={ product }/>
              { this.renderNote() }
            </div>
          </div>
          <div className="product-details grid">
            <AddToCart
               product_unit={ product }
               seller_id={ product.seller_id }
               showPricing={ this.props.showPricing }
               cartEnabled={ this.props.cartEnabled }
               addItem={ this.props.addItem }
               src='catalog_card'
            />
          </div>
        </div>
      </div>
    )
  }

  render() {
    if (this.props.type == "list") {
      return this.renderListProduct()
    } else {
      return this.renderGridProduct()
    }
  }
}
