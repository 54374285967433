import { timeSince } from './time_since.js';

document.addEventListener("turbo:before-cache", function() {
  $(".toast").remove()
})

document.addEventListener('turbo:load', () => {
  $('#featured-selections').on('click', 'a.list-group-item-action', function () {
    $(this).find('.text-muted.small').append('<span class="fa fa-spinner fa-pulse ml-2">')
  })
});

document.addEventListener('turbo:load', () => {
  $(document).on('click', '.modal-close-and-reload', function () {
    $('.modal').modal('dispose')
    window.location.reload();
  })
});

document.addEventListener('turbo:load', () => {
  $('[data-toggle="time-ago"]').each(
    function() {
      var date = new Date($(this).data('date'))
      var formatted = timeSince(date)
      $(this).html(formatted)
    }
  )
})

document.addEventListener("turbo:load", function(){
  $('body').on('click', '.input-fill-button', function () {
    $($(this).data('target')).val($(this).data('value'))
  });
});


document.addEventListener("turbo:load", function(){
  $('#sales-order-dropdown').on('shown.bs.dropdown', function () {
    $(this).find('#sales_order_go').trigger("focus")
  });
});

document.addEventListener("turbo:load", function(){
  $('.toggle-button').on('click', function () {
    $(this).toggleClass('active')
  });
});

document.addEventListener("turbo:load", function(){
  $('#purchase-order-dropdown').on('shown.bs.dropdown', function () {
    $(this).find('#purchase_order_go').trigger("focus")
  });
});

document.addEventListener("turbo:load", function(){
  $('.inventory-location-dropdown').on('shown.bs.dropdown', function () {
    $(this).find('.form-control:first').trigger("focus")
  });
});


$(document).on('keyup', ".recalculate-laid-in", function() {
  const form = $(this).closest('form')
  const price = Number(form.find('#product_unit_price').val())
  const freight = Number(form.find('#product_unit_freight_cost').val())
  const pad_pct = Number(form.find('#product_unit_pad_pct').val())

  let laid_in_cost = ((price + freight) * ( 100 + pad_pct ) / 100).toFixed(2)
  if ( isNaN(laid_in_cost) ) { laid_in_cost = '------' }
  form.find('#product_unit_laid_in_cost').val(laid_in_cost)
});

$(document).on('change', "select.submit-on-change, input.submit-on-change", function( event ) {
  Rails.fire(this.form, "submit")
});

$(document).on('show.bs.popover', '.popover-product-unit', function () {
  var popover =  $(this)
  var product_unit_id = popover.data().id
  var as_of_date = popover.data().date
  Rails.ajax({
    url: "/admin/product_units/" + product_unit_id + "/buyer_order_items/?on=" + as_of_date ,
    dataType: "script",
    type: "get"
  })
})

document.addEventListener('turbo:load', () => {
  var options = {
    delay: { show: 300, hide: 50 },
    trigger: 'manual',
    html: true
  };
  var $popover = $('.popover-product-unit').popover(options);

  $popover.on('mouseenter', function () { // This is entering the triggering element
    var self = this;

    clearTimeout(self.timeout);
    self.hoverState = 'in';

    self.timeout = setTimeout(function () {
      if (self.hoverState == 'in') {
        $(self).popover("show");

        $(".popover, .popover *").on('mouseover', function () { // This is moving over the popover
          clearTimeout(self.timeout);
        });

        $(".popover").on('mouseleave', function () { // This is leaving the popover
          self.timeout = setTimeout(function () {
            if (self.hoverState == 'out') {
              $(self).popover('hide');
            }
            }, options.delay.hide);
          });
        }
    }, options.delay.show);
  }).on('mouseleave', function (event) { // This is leaving the triggering element
      var self = this;

      clearTimeout(self.timeout);
      self.hoverState = 'out';

      self.timeout = setTimeout(function () {
        if (self.hoverState == 'out') {
          $(self).popover('hide');
        }
      }, options.delay.hide);
  });
})


$(document).on('show.bs.popover', '.order-popover', function () {
  var popover =  $(this)
  var id = popover.data().id
  Rails.ajax({
    url: "/admin/sales_orders/" + popover.data().id,
    dataType: "script",
    type: "get"
  })
})

document.addEventListener('turbo:load', () => {
  var options = {
    delay: { show: 300, hide: 50 },
    trigger: 'manual',
    html: true
  };
  var $popover = $('.order-popover').popover(options);

  $popover.on('mouseenter', function () { // This is entering the triggering element
    var self = this;

    clearTimeout(self.timeout);
    self.hoverState = 'in';

    self.timeout = setTimeout(function () {
      if (self.hoverState == 'in') {
        $(self).popover("show");

        $(".popover, .popover *").on('mouseover', function () { // This is moving over the popover
          clearTimeout(self.timeout);
        });

        $(".popover").on('mouseleave', function () { // This is leaving the popover
          self.timeout = setTimeout(function () {
            if (self.hoverState == 'out') {
              $(self).popover('hide');
            }
            }, options.delay.hide);
          });
        }
    }, options.delay.show);
  }).on('mouseleave', function (event) { // This is leaving the triggering element
      var self = this;

      clearTimeout(self.timeout);
      self.hoverState = 'out';

      self.timeout = setTimeout(function () {
        if (self.hoverState == 'out') {
          $(self).popover('hide');
        }
      }, options.delay.hide);
  });
})

$(document).on('show.bs.popover', '.order-item-popover', function () {
  var popover =  $(this)
  var id = popover.data().id
  Rails.ajax({
    url: "/admin/product_units/" + popover.data().id + "/order_items",
    dataType: "script",
    type: "get"
  })
})

$(document).on('click', '.popover-order-items tr', function () {
  var target =  $(this).data('target')
  window.open(target, '_blank')
})

document.addEventListener('turbo:load', () => {
  var options = {
    delay: { show: 300, hide: 50 },
    trigger: 'manual',
    html: true
  };
  var $popover = $('.order-item-popover').popover(options);

  $popover.on('mouseenter', function () { // This is entering the triggering element
    var self = this;

    clearTimeout(self.timeout);
    self.hoverState = 'in';

    self.timeout = setTimeout(function () {
      if (self.hoverState == 'in') {
        $(self).popover("show");

        $(".popover, .popover *").on('mouseover', function () { // This is moving over the popover
          clearTimeout(self.timeout);
        });

        $(".popover").on('mouseleave', function () { // This is leaving the popover
          self.timeout = setTimeout(function () {
            if (self.hoverState == 'out') {
              $(self).popover('hide');
            }
            }, options.delay.hide);
          });
        }
    }, options.delay.show);
  }).on('mouseleave', function (event) { // This is leaving the triggering element
      var self = this;

      clearTimeout(self.timeout);
      self.hoverState = 'out';

      self.timeout = setTimeout(function () {
        if (self.hoverState == 'out') {
          $(self).popover('hide');
        }
      }, options.delay.hide);
  });
})

$(document).on('show.bs.dropdown', '.alternatives-dropdown', function () {
  var dropdownMenu = $(this)
  var item_id = dropdownMenu.data().id
  Rails.ajax({
    url: "/admin/checkout_items/" + item_id + "/alternatives",
    dataType: "script",
    type: "get"
  })
})
$(document).on('hide.bs.dropdown', '.alternatives-dropdown', function () {
  var spinner = "<div class='dropdown-item disabled py-5'>Looking for alternatives <span class='fa fa-fw fa-spinner fa-pulse'></span></div>"
  $(this).find('.alternatives').html(spinner)
})


document.addEventListener('turbo:load', () => {
  new ClipboardJS('.clipboard-button');

  $('html').on('show.bs.dropdown', '.available-on-dropdown', function() {
    var form = $(this).find('form')[0]
    Rails.fire(form, "submit")
  })


  $('form').on('click', '.availability-toggler', function () {
    var input = $(this).find("input")
    var icon = $(this).find("span")
    if (input.val() == "true") {
      input.val("false")
      icon.removeClass("fa-toggle-on")
      icon.addClass("fa-toggle-off text-muted")
    } else {
      input.val("true")
      icon.removeClass("fa-toggle-off text-muted")
      icon.addClass("fa-toggle-on")
    }
  });

  $(".send-form-on-change").change(function(){
    var form = $(this).closest('form')[0];
    Rails.fire(form, "submit")
  });

  $("select[name='product_unit[packing_volume_id]']").change(function(){
    var form = $(this).closest('form')[0];
    Rails.fire(form, "submit")
  });

  $(".toggler").click(function () {
    var checkAll = $(this).text() == 'show details'
    if (checkAll) {
      $(this).text('hide details');
    } else {
      $(this).text('show details');
    }
  });

  $(".unit-edit-button-enabler").click(function () {
    $(this).siblings(".unit-edit-enabler").removeClass('d-none')
  });

  $(".unit-edit-enabler").click(function () {
    var input = $(this).closest("form").find('input[name="product_unit[unit]"]')
    input.prop('disabled', false);
    $(this).remove()
  });



  $(document).on('change', "#route_run_vehicle_id", function(event) {
    if (event.target.value == "0") {
      window.open(event.target.dataset.url, '_blank');
    }
  })

  $('#order-receiving').on('click', '.additive-button', function(){
    var add = Number($(this).data('plus'))
    var quantityInput = $(this).closest('.receiving-item').find('.quantity');
    var currentQty = Number(quantityInput.val());

    quantityInput.val(Math.max(currentQty + add, 0))
  });

  $("#per_page").change(function(){
    $(this).closest('form').submit();
  });

  $('.checkbox-input-toggler input[type="checkbox"]').click(function() {
    // checkbox click events are weird and rather than fighting it, i'm hacking it
    var checked = $(this).is(":checked")
    $(this).prop("checked", checked ? false : true)
  })

  $('.checkbox-input-toggler').click(function() {
    var checked = $(this).find('input').is(":checked")
    $(this).find('input').prop("checked", checked ? false : true)
    $(this).toggleClass("btn-outline-success")
    $(this).toggleClass("btn-outline-secondary")
    $(this).find('.fa').toggleClass("fa-lg")
  })

  $('form').on('click', '.copy-all-estimates', function() {
    $('input[data-estimate]').each(
      function() {
        var current_value = $(this).val()
        var estimate_value = $(this).data().estimate;
        if (!current_value && estimate_value == 0) { return }
        $(this).val(estimate_value) }
    )
    return event.preventDefault();
  });

  $('form').on('click', '.copy-blanks-estimates', function() {
    $('input[data-estimate]').each(
      function() {
        var current_value = $(this).val()
        if (current_value) { return }
        var estimate_value = $(this).data().estimate
        if (!current_value && estimate_value == 0) { return }
        $(this).val(estimate_value)
      }
    )
    return event.preventDefault();
  });

  var updatePaymentTotal = function() {
    var newTotal = 0
    $('tbody input.apply-payment-destination').each(
      function() { newTotal += Number($(this).val())}
    )
    newTotal = Number(newTotal.toFixed(2))
    var expectedTotal = Number($('#payment_value').data('value') || $('#payment_value').val())
    $('#calculated_payment_total').val(newTotal.toFixed(2))
    if (newTotal === expectedTotal) {
      $('#calculated_payment_total').addClass('is-valid')
      $('#calculated_payment_total').removeClass('is-invalid')
    } else {
      $('#calculated_payment_total').addClass('is-invalid')
      $('#calculated_payment_total').removeClass('is-valid')
    }
  }
  window.updatePaymentTotal = updatePaymentTotal

  var updateRemainingBalance = function() {
    var expectedTotal = Number($('#payment_value').val())
    var totalApplied = Number($('#calculated_payment_total').val())
    var newValue = expectedTotal ? (expectedTotal - totalApplied).toFixed(2) : 0
    $('#payment_new_credit').val(newValue)
  }

  $('form').on('click', '.apply-payment', function() {
    var sumApplied = 0
    $(this).closest('tr').find('input.apply-payment-destination').val('')
    $('tbody input.apply-payment-destination').each(
      function() { sumApplied += Number($(this).val())}
    )
    var remaining = Number($(this).data('remaining'))
    var expectedTotal = Number($('#payment_value').val())
    var totalRemaining = expectedTotal - sumApplied

    var toSet = expectedTotal ? Math.min(totalRemaining, remaining) : remaining

    $(this).closest('tr').find('input.apply-payment-destination').val(toSet.toFixed(2))

    updatePaymentTotal();
    updateRemainingBalance();
    return event.preventDefault();
  });

  $('form tbody').on('change', 'input', function() {
    updatePaymentTotal();
    updateRemainingBalance();
  })

  if ( $('#card-element').data('pk') ) {
    const pk = $('#card-element').data('pk')
    const stripe = Stripe(pk);

    const cardOptions = {
      iconStyle: 'solid',
      style: {
        base: {
          fontSize: '17.5px',
          fontWeight: 400,
          fontFamily: 'Helvetica Neue',
          color: '#495057',
          backgroundColor: '#fff'
        }
      }
    }

    var elements = stripe.elements();
    var cardElement = elements.create('card', cardOptions);
    cardElement.mount('#card-element');

    var cardButton = document.getElementById('card-button');
    var clientSecret = cardButton.dataset.secret;

    cardButton.addEventListener('click', function(ev) {
      var radioButton = document.querySelector('input[name="payment_method"]:checked');
      var payload = {};
      if (radioButton.value == 'new_card') {
        var cardholderName = document.getElementById('cardholder-name');
        payload = {
          payment_method: {
            card: cardElement,
            billing_details: { name: cardholderName.value }
          },
          setup_future_usage: 'off_session'
        }
      } else {
        payload = { payment_method: radioButton.value }
      }

      $('#stripe-error-message').slideUp();
      $('#stripe-error-message .alert').text('')
      $(cardButton).text('Submitting...');
      $(cardButton).prop('disabled', true);
      stripe.confirmCardPayment(
        clientSecret,
        payload
      ).then(function(result) {
        if (result.error) {
          $('#stripe-error-message .alert').text(result.error.message)
          $('#stripe-error-message').slideDown();
          $(cardButton).text('Submit Payment');
          $(cardButton).prop('disabled', false);
        } else {
          $('#stripe').slideUp(400, () => {
            const confirmation = `
              <h3>Thank you for your payment!</h3>
              <p>You should soon receive a confirmation email with your order details.</p>
              <div>
                <a href='/admin/dashboard' class='btn btn-primary'>Back to Dashboard</a>
              </div>
            `
            $('#stripe').html(confirmation);
            $('#stripe').slideDown();
          });
        }
      });
      return event.preventDefault();
    });
  }
});
