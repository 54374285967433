import React from "react"
import { UncontrolledTooltip } from "reactstrap"
import { formatDate } from "../../services/Utils"

export class Forecast extends React.Component {
  constructor(props) {
    super(props)
  }

  renderForecastUpArrow(forecast) {
    const arrowClass = forecast.in_qty > 0 ? 'fa fa-caret-up fa-2x text-success' : 'fa'
    const arrow = <span className={ arrowClass }></span>

    return (
      <div style={{height: '5px', position: 'relative', top: '-15px'}}>
        { arrow }
      </div>
    )
  }

  renderForecastDownArrow(forecast) {
    const arrowClass = forecast.out_qty > 0 ? 'fa fa-caret-down fa-2x text-danger' : 'fa'
    const arrow = <span className={ arrowClass }></span>

    return (
      <div style={{height: '5px', position: 'relative', top: '-14px'}}>
        { arrow }
      </div>
    )
  }

  // match with ForecastHelper#forecast_styling
  forecastStyling(forecast) {
    if (forecast.close_qty < 0) {
      return 'border border-danger text-danger font-weight-bold'
    } else if (forecast.is_sequence) {
      return 'bg-warning'
    } else if (forecast['issue?']) {
      return 'alert-danger'
    } else if (forecast.is_replenishment) {
      return 'border border-info small'
    } else {
      return 'text-muted small'
    }
  }

  renderForecast() {
    const multiplier = this.props.product_unit.split_multiplier || 1
    const forecasts = this.props.forecast.forecasts
    const forecast_columns = forecasts.map((forecast_day) => {
      const quantity = forecast_day.close_qty * multiplier
      let openOrder
      if (forecast_day.earliest_draft_po) {
        const title = `Draft PO: ${ formatDate(forecast_day.earliest_draft_po) }`
        const id = `tooltip-${ forecast_day.id }`
        openOrder = <div id={id} className="p-1 bg-success">
          <UncontrolledTooltip placement="bottom" target={id} >{ title }</UncontrolledTooltip>
        </div>
      } else {
        openOrder = <div className="p-1"></div>
      }

      return (
        <td className={`text-center h5`} key={ forecast_day.on }>
          { this.renderForecastUpArrow(forecast_day) }
          <div className={ this.forecastStyling(forecast_day) }>
            { parseFloat(Number(quantity).toFixed(3)) }
          </div>
          { this.renderForecastDownArrow(forecast_day) }
          { openOrder }
        </td>
      )
    })
    return (
      <tr>
        { forecast_columns }
      </tr>
    )
  }

  renderHeader() {
    const dateOptions = { timeZone: 'UTC', weekday: 'short', month: 'numeric', day: 'numeric' }
    const headers = this.props.forecast.forecasts.map((forecast_day) => {
      const date = forecast_day.on
      const formattedDate = new Date(date).toLocaleDateString("en-US", dateOptions).replace(',','').replace(' ', '\n')
      return (
        <th key={date} className='small text-center'>
          { formattedDate }
        </th>
      )
    })
    return (
      <tr>
        { headers }
      </tr>
    )
  }

  render() {
    if (!this.props.forecast ||this.props.forecast.forecasts.length == 0) {
      return (
        <div>
          <div>
              <span className='fa fa-exclamation-triangle mr-1'></span>
              No forecast available
          </div>
          <div>
            <a href={`/admin/checkout_items/${this.props.order_item_id}`}>
              View Alternatives
            </a>
          </div>
        </div>
      )
    }
    return (
      <div>
        <table className='table table-sm table-bordered mb-1' >
          <thead>
            {  this.renderHeader() }
          </thead>
          <tbody>
            { this.renderForecast() }
          </tbody>
        </table>
        <a className='btn mr-1 btn-sm btn-outline-secondary' href={`/admin/product_units/${this.props.product_unit.id}/order_items?modal=t`} data-remote='true'>
          <span className='far fa-list-alt'></span>
        </a>
        <a href={`/admin/checkout_items/${this.props.order_item_id}`}>
          View Alternatives
        </a>

      </div>
    )
  }
}
export default Forecast