document.addEventListener("turbo:load", function(){
  mapboxElement = document.getElementById('mapbox-map');
  if (!mapboxElement) { return }

  mapboxgl.accessToken = mapboxElement.dataset.token
  var map = new mapboxgl.Map({
    container: 'mapbox-map',
    style: 'mapbox://styles/mapbox/streets-v12',
    bounds: JSON.parse(mapboxElement.dataset.bounds)
  });

  var routes  = JSON.parse(mapboxElement.dataset.routeMarkers);

  routes.forEach(function(route, index) {
    route.markers.forEach(function(marker) {
      // create a HTML element for each feature
      var el = document.createElement('div');
      el.className = 'marker';
      if ( !marker.coordinates ) { alert( `${ marker.name } has no address`); return }
      // make a marker for each feature and add it to the map
      new mapboxgl.Marker({ color: route.color})
        .setLngLat(marker.coordinates)
        .setPopup(new mapboxgl.Popup({ offset: 25 }) // add popups
        .setHTML(`<div>${ marker.name }</div><div>${ route.name }</div>`))
        .addTo(map);
    })
  });
})

document.addEventListener("turbo:load", function(){
  mapboxElement = document.getElementById('mapbox-directions');
  if (!mapboxElement) { return }

  mapboxgl.accessToken = mapboxElement.dataset.token

  var routes = JSON.parse(mapboxElement.dataset.routes)

  var map = new mapboxgl.Map({
    container: mapboxElement.id,
    style: 'mapbox://styles/mapbox/streets-v12',
    bounds: JSON.parse(mapboxElement.dataset.bounds)
  });

  map.loadImage('https://cdn-icons-png.flaticon.com/128/17470/17470552.png', (error, image) => {
    if (error) throw error;
    map.addImage('arrow', image);
  })

  routes.forEach(function(solution_route, index) {
    var stops = solution_route.stops;

    for (let i = 0; i < stops.length; i += 24) {
      const limited_stops = stops.slice(Math.max(0, i-1), i + 24);
      var coordinates = limited_stops.map(n => n.coordinates )
      const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${coordinates.join(';')}?geometries=geojson&access_token=${mapboxgl.accessToken}`;
      fetch(url)
      .then(response => response.json())
      .then(data => {
        const route = data.routes[0].geometry;
        const sourceName = `route-${ index }-${ i }`
        // Step 5: Add Route to the Map
        map.on('load', () => {
          map.addSource(sourceName, {
            type: 'geojson',
            data: {
              type: 'Feature',
              properties: {},
              geometry: route
            }
          });

          map.addLayer({
            id: `route-line-${ index }-${ i }`,
            type: 'line',
            source: sourceName,
            layout: {
              'line-join': 'round',
              'line-cap': 'round'
            },
            paint: {
              'line-color': solution_route.color,
              'line-width': 8
            }
          });

          map.addLayer({
            id: `route-arrows-${ index }-${ i }'`,
            type: 'symbol',
            source: sourceName,
            layout: {
              'symbol-placement': 'line',
              'symbol-spacing': 100, // Adjust spacing between arrows
              'icon-image': 'arrow',
              'icon-allow-overlap': true,
              'icon-ignore-placement': true,
              'icon-size': 0.15 // Adjust arrow size
            }
          });
        });
        // Step 6: Add Markers for Each Coordinate
        for (let index = stops.length; index > 0; index -= 1) {
          const stop = stops[index-1]
          const el = document.createElement('div');
          el.className = 'map-pin';
          if (stop.type == 'end') { el.className = 'map-pin map-end' }
          if (stop.type == 'start') { el.className = 'map-pin map-start' }
          const numberEl = document.createElement('div');
          numberEl.className = 'map-pin-number';
          numberEl.textContent = index + 1; // Display the index as a label
          el.appendChild(numberEl);

          new mapboxgl.Marker(el)
            .setLngLat(stop.coordinates)
            .setPopup(new mapboxgl.Popup({ offset: 25 })
            .setHTML(`<div>${ stop.name }</div><div>${ stop.eta }</div>`))
            .addTo(map);

        }
      })
    }
  })
})
