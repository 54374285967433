const { parseISO, format } = require('date-fns');

export function asCurrency(price) {
  const numericPrice = Number(price)
  const asCurrency = numericPrice.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
  if (numericPrice < 0)
    return "(" + asCurrency.substr(1) + ")"
  else
    return asCurrency
}

export function isTomorrow(dateString) {
  const tomorrowDate = new Date();
  tomorrowDate.setDate(tomorrowDate.getDate() + 1)
  return dateString == tomorrowDate.toLocaleDateString('en-CA', { timeZone: "America/New_York" })
}

export function stripZeros(numberString) {
  const numericNumber = Number(numberString)
  return parseFloat(numericNumber.toFixed(4));
}

export function formatDate(dateString) {
  const date = parseISO(dateString);
  const formattedDate = format(date, 'EEE M/d');
  return formattedDate;
}