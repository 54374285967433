import ahoy from 'ahoy.js';

// override defaults
ahoy.configure({
  visitsUrl: "/ahoyyy/visits",
  eventsUrl: "/ahoyyy/events"
})

document.addEventListener("turbo:load", function(){
  ahoy.trackClicks(".dataTable th.sorting");
});
